$spacing-unit:   30px;
$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: ui-monospace,
           Monaco,
           Menlo,
           -apple-system,
           BlinkMacSystemFont,
           "Segoe UI",
           Helvetica,
           "Apple Color Emoji",
           "Segoe UI Emoji",
           "Segoe UI Symbol";

$linkColor:    black;
$on-palm:      1100px;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

.header {
  height: $spacing-unit * 2.5;
  border-bottom: 1.5px solid black;
  margin-bottom: $spacing-unit;

  h1 {
    margin-bottom: 0px;
  }

  @media (prefers-color-scheme: dark) {
    border-bottom: 1.5px solid #ff69b4;
  }
}

h1, h2, h3, h4, h5 {
  margin: 0 auto;
  text-transform: lowercase;
}

h1 {
  font-variant: petite-caps;
  text-transform: lowercase;
  margin-bottom: calc($spacing-unit / 2);
}

h2 {
  margin-top: $spacing-unit;
}


body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-size: 12px;
  line-height: 1.5;

  @media (prefers-color-scheme: dark) {
    background: black;
    color: darkgrey;
  }

}

a {
  color: $linkColor;
  text-decoration-color: gray;
  text-underline-offset: 4px;

  &:visited {
    // leave color as it is
  }

  &:hover {
    // leave color as it is
  }

  @media (prefers-color-scheme: dark) {
    color: darkgrey;
  }
}

blockquote {
  margin-left: calc($spacing-unit / 2);
  padding-left: calc($spacing-unit / 2);
  border-left: 1px solid lightgray;
}

mark {
  background-color: #e7e7e7;
}

.pre-footer {
  display: flex;
  width: 100%;
}

.pre-footer a {
  width: 50%;
  font-variant: all-small-caps;
}

.pre-footer span {
  font-family: -apple-system, serif;
  font-size: 1.3em;
  line-height: .5;
}
  
.footer {
  text-align: center;
  letter-spacing: .05em;
  font-variant: petite-caps;
}

.footer span {
  font-family: -apple-system, serif;
  font-size: 1.3em;
  line-height: .7;
}

.wrapper {
  width: 100%;
  text-align: center;
}

.timestamp {
    padding-bottom: $spacing-unit;
}

.bulletin {
  width: 50%;
  @include media-query($on-palm) {
    width: 80%;
  }

  margin: 0 auto;
  text-align: left;
  border: 1px black solid;
  padding: $spacing-unit;

  @media (prefers-color-scheme: dark) {
    border: 1px darkgrey solid;
  }
}

div.photocontainer {
  text-align: center;

  img {
    border: 1px solid black;
    @include media-query($on-palm) {
      width: 90%;
    }
  }

  video {
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
    max-width: 500px;
    padding: 2px;
    @include media-query($on-palm) {
      width: 90%;
    }
  }
}

div.previousnextlinks {
  padding: $spacing-unit;
  font-variant: all-small-caps;

  a {
    margin: $spacing-unit * 2.5;
  }
}

.post-separator {
  width: 100%;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;

  hr {
    border: .5px solid grey;
  }

}

.blink {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}
